<template>
  <!-- index START -->
  <div class="index" v-if="box">
    <div
      :class="[
        'inner',
        { 'hide' : loginError || loginSuccess }
      ]"
    >
      <div class="animated-on-change">
        <!-- TEST ----- START -->
        <input
          type="text"
          class="test-input-terminal"
          :placeholder="'QR Code'"
          v-model="qrcodeTest"
          v-on:keyup.enter="onEnter"
          v-if="showQrcodeTest"
        />
        <!-- TEST ----- END -->

        <!-- index:main:headline START -->
        <h1
          class="headline"
          v-html="$t('outdoor.index.headline')"
        ></h1>
        <!-- index:main:headline END -->

        <!-- index:main:box:status START -->
        <box-status
          :boxOpened="boxOpened"
          :fakeHours="fakeHours"
          :fakeHoursManned="fakeHoursManned"
          :box="box"
        />
        <!-- index:main:box:status END -->

        <!-- index:main:box:error START -->
        <box-error
          v-if="boxOpenedCatchError.isError"
          :boxOpenedCatchError="boxOpenedCatchError"
        />
        <!-- index:main:box:error END -->

        <!-- index:qr-codes START -->
        <div
          class="qr-codes"
          v-if="boxOpened.isOpen && (box.hasAppCheckin === 1 || box.hasSmsCheckin === 1)"
        >
          <div class="qr-code download">
            <div class="inner">
              <img :src="`${rootUrl}/qr/?d=http://anybox-www.int5.syreta.com/?boxID=${box.id}&locale=${boxLang}&fg=353635`"/>
            </div>
          </div>
        </div>
        <!-- index:qr-codes END -->
      </div>

      <!-- index:scan START -->
      <div
        :class="[
          'scan',
          { 'hide' : !boxOpened.isOpen
          || boxOpenedCatchError.isError
          || loginError
          || loginSuccess }
        ]"
      >
        <box-bottom-info
          :boxOpened="boxOpened"
          :box="box"
        />
      </div>
      <!-- index:scan END -->

      <!-- index:made-by START -->
      <div :class="['made-by']" v-if="false">
        <span>made by</span>
        <span class="logo">
          <!-- eslint-disable max-len -->
          <svg width="903" height="296" viewBox="0 0 903 296" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M472.604 132.808C478.491 138.694 488.025 138.725 493.951 132.877C513.48 113.607 552.887 74.721 552.887 74.721L531.461 53.3008C531.461 53.3008 492.253 91.989 472.673 111.309C469.8 114.144 468.177 118.008 468.164 122.044C468.151 126.079 469.749 129.954 472.604 132.808Z" fill="#00C3FF"/>
            <path d="M88.894 107.851C88.894 100.564 86.7661 96.4089 82.515 91.9967C78.2497 87.5856 72.6484 85.4049 65.6831 85.4049C59.1865 85.4049 52.9685 87.5365 48.5527 91.8979C44.1473 96.2107 42.0132 100.415 40.619 107.851H88.894ZM112.567 74.4992C123.556 86.5444 129.047 103.1 129.047 124.167C129.047 127.389 128.966 129.867 128.813 131.602H41.5477C43.5574 138.69 47.0387 144.152 51.9906 147.944C56.9419 151.736 62.9795 153.63 70.0984 153.63C75.5058 153.63 80.7291 152.549 85.7578 150.378C90.7876 148.217 95.3901 145.044 99.5748 140.871L121.155 162.453C114.655 169.729 106.805 175.255 97.6017 179.043C88.3914 182.835 77.9851 184.728 66.3797 184.728C52.9183 184.728 41.1592 182.101 31.1007 176.842C21.0432 171.583 13.3448 164.153 8.00801 154.556C2.67335 144.965 0 133.832 0 121.143C0 108.305 2.70368 96.9538 8.12829 87.139C13.5388 77.3252 21.1975 69.7413 31.1007 64.3879C41.0018 59.0344 52.456 56.3584 65.4525 56.3584C85.8786 56.3584 101.578 62.4048 112.567 74.4992Z" fill="#25282A"/>
            <path d="M212 90.51C204.57 90.51 198.537 93.3355 193.898 98.986C189.249 104.637 186.934 111.923 186.934 120.895C186.934 130.016 189.249 137.401 193.898 142.952C198.537 148.519 204.57 151.305 212 151.305C223.446 151.305 231.644 146.586 236.601 137.153L267.011 154.556C262.054 164.148 254.624 171.583 244.725 176.842C234.822 182.106 223.218 184.733 209.908 184.733C197.066 184.733 185.775 182.106 176.024 176.842C166.279 171.583 158.735 164.148 153.397 154.556C148.056 144.965 145.386 133.832 145.386 121.143C145.386 108.305 148.093 96.9538 153.516 87.139C158.923 77.3252 166.547 69.7413 176.371 64.3879C186.2 59.0344 197.532 56.3584 210.38 56.3584C222.91 56.3584 234.048 58.9847 243.799 64.14C253.539 69.3449 261.122 76.6308 266.539 86.0989L236.601 103.497C230.717 94.8223 222.524 90.51 212 90.51Z" fill="#25282A"/>
            <path d="M344.371 90.51C336.94 90.51 330.913 93.3355 326.268 98.986C321.624 104.637 319.309 111.923 319.309 120.895C319.309 130.016 321.624 137.401 326.268 142.952C330.913 148.519 336.94 151.305 344.371 151.305C355.816 151.305 364.019 146.586 368.976 137.153L399.381 154.556C394.424 164.148 386.998 171.583 377.095 176.842C367.196 182.106 355.588 184.733 342.284 184.733C329.435 184.733 318.144 182.106 308.395 176.842C298.649 171.583 291.105 164.148 285.766 154.556C280.428 144.965 277.757 133.832 277.757 121.143C277.757 108.305 280.463 96.9538 285.886 87.139C291.294 77.3252 298.917 69.7413 308.746 64.3879C318.566 59.0344 329.906 56.3584 342.749 56.3584C355.285 56.3584 366.419 58.9847 376.168 64.14C385.913 69.3449 393.492 76.6308 398.91 86.0989L368.976 103.497C363.092 94.8223 354.894 90.51 344.371 90.51Z" fill="#25282A"/>
            <path d="M564.218 57.7461H602.888L634.51 145.04L665.37 57.7461H703.877L656.762 183.342H611.109L564.218 57.7461Z" fill="#25282A"/>
            <path d="M724.122 57.7457H760.677V183.335H724.122V57.7457ZM758.024 6.14629C762.118 10.2112 764.175 15.5145 764.175 22.008C764.175 28.3525 762.118 33.6563 758.024 37.8201C753.919 41.9839 748.695 44.0652 742.356 44.0652C736.005 44.0652 730.786 41.9839 726.687 37.8201C722.587 33.6563 720.54 28.3525 720.54 22.008C720.54 15.5145 722.587 10.2112 726.687 6.14629C730.786 2.03168 736.005 0 742.356 0C748.695 0 753.919 2.03168 758.024 6.14629Z" fill="#25282A"/>
            <path d="M852.109 152.584C856.749 149.565 859.686 145.659 860.928 140.87V129.966H836.325C824.721 129.966 818.918 134.278 818.918 142.952C818.918 147.29 820.422 150.725 823.447 153.278C826.464 155.835 830.594 157.114 835.864 157.114C842.048 157.114 847.469 155.602 852.109 152.584ZM886.924 68.1055C896.515 76.0858 901.386 87.2383 901.545 101.663V183.341H860.928V169.179C852.579 179.856 839.963 185.189 823.097 185.189C809.789 185.189 799.304 181.595 791.646 174.409C783.983 167.206 780.162 157.733 780.162 145.966C780.162 134.03 784.295 124.761 792.573 118.119C800.85 111.477 812.726 108.057 828.199 107.909H860.928V106.52C860.928 100.473 858.875 95.8132 854.775 92.5923C850.668 89.3202 844.754 87.7346 837.018 87.7346C831.765 87.7346 825.843 88.5766 819.268 90.2626C812.686 91.9475 806.079 94.4258 799.424 97.6974L788.048 69.8401C798.264 65.1811 808.045 61.761 817.414 59.5301C826.774 57.2505 836.484 56.1602 846.537 56.1602C863.865 56.1602 877.325 60.1247 886.924 68.1055Z" fill="#25282A"/>
            <path d="M477.14 88.1097L507.481 58.4696L505.43 57.748C498.052 55.152 490.313 53.8535 482.583 53.8535C465.141 53.8535 447.714 60.5039 434.403 73.8042C421.139 87.0523 414.467 104.458 414.405 121.811V122.318C414.467 139.732 421.137 157.088 434.4 170.356L434.401 170.356C447.668 183.624 465.045 190.285 482.432 190.353H482.918C500.322 190.285 517.693 183.624 530.96 170.357L530.961 170.356C550.089 151.223 555.421 123.557 547.096 99.6236L546.914 99.0996L516.304 129.429C514.961 135.656 511.878 141.58 507.034 146.436C493.58 159.891 471.773 159.89 458.32 146.434C444.868 132.972 444.865 111.173 458.318 97.7321L458.319 97.7305C463.631 92.4199 470.249 89.2257 477.14 88.1097Z" fill="#00C3FF"/>
            <path d="M885.165 296C881.865 296 878.895 295.434 876.255 294.303C873.615 293.124 871.54 291.474 870.032 289.353C868.523 287.231 867.769 284.756 867.769 281.927H876.396C876.585 284.049 877.41 285.793 878.871 287.16C880.38 288.528 882.478 289.211 885.165 289.211C887.947 289.211 890.116 288.551 891.671 287.231C893.227 285.864 894.005 284.119 894.005 281.998C894.005 280.348 893.51 279.004 892.52 277.967C891.577 276.93 890.375 276.128 888.913 275.563C887.499 274.997 885.519 274.384 882.973 273.724C879.767 272.875 877.151 272.027 875.123 271.178C873.143 270.282 871.446 268.915 870.032 267.076C868.617 265.238 867.91 262.786 867.91 259.722C867.91 256.893 868.617 254.418 870.032 252.296C871.446 250.175 873.426 248.548 875.972 247.417C878.518 246.285 881.464 245.72 884.812 245.72C889.573 245.72 893.463 246.922 896.48 249.326C899.545 251.684 901.242 254.937 901.572 259.085H892.661C892.52 257.294 891.671 255.762 890.116 254.489C888.56 253.216 886.509 252.579 883.963 252.579C881.653 252.579 879.767 253.169 878.306 254.347C876.844 255.526 876.113 257.223 876.113 259.439C876.113 260.948 876.561 262.197 877.457 263.187C878.4 264.13 879.579 264.884 880.993 265.45C882.407 266.016 884.34 266.629 886.792 267.289C890.045 268.184 892.685 269.08 894.712 269.976C896.787 270.872 898.531 272.262 899.945 274.148C901.407 275.987 902.138 278.462 902.138 281.574C902.138 284.072 901.454 286.43 900.087 288.645C898.767 290.861 896.81 292.653 894.217 294.02C891.671 295.34 888.654 296 885.165 296Z" fill="#25282A"/>
            <path d="M856.97 295.506H848.908L824.652 258.803V295.506H816.59V246.286H824.652L848.908 282.918V246.286H856.97V295.506Z" fill="#25282A"/>
            <path d="M782.138 296C777.565 296 773.345 294.939 769.479 292.818C765.661 290.649 762.62 287.655 760.357 283.837C758.141 279.971 757.033 275.633 757.033 270.825C757.033 266.016 758.141 261.702 760.357 257.883C762.62 254.064 765.661 251.094 769.479 248.973C773.345 246.804 777.565 245.72 782.138 245.72C786.758 245.72 790.978 246.804 794.796 248.973C798.662 251.094 801.703 254.064 803.919 257.883C806.135 261.702 807.243 266.016 807.243 270.825C807.243 275.633 806.135 279.971 803.919 283.837C801.703 287.655 798.662 290.649 794.796 292.818C790.978 294.939 786.758 296 782.138 296ZM782.138 288.999C785.391 288.999 788.29 288.268 790.836 286.807C793.382 285.298 795.362 283.177 796.777 280.442C798.238 277.661 798.969 274.455 798.969 270.825C798.969 267.194 798.238 264.012 796.777 261.278C795.362 258.543 793.382 256.445 790.836 254.984C788.29 253.522 785.391 252.791 782.138 252.791C778.885 252.791 775.986 253.522 773.44 254.984C770.894 256.445 768.89 258.543 767.429 261.278C766.014 264.012 765.307 267.194 765.307 270.825C765.307 274.455 766.014 277.661 767.429 280.442C768.89 283.177 770.894 285.298 773.44 286.807C775.986 288.268 778.885 288.999 782.138 288.999Z" fill="#25282A"/>
            <path d="M747.722 246.356V295.505H739.66V246.356H747.722Z" fill="#25282A"/>
            <path d="M730.081 246.356V252.933H716.998V295.505H708.936V252.933H695.783V246.356H730.081Z" fill="#25282A"/>
            <path d="M656.08 246.356V277.684C656.08 281.409 657.046 284.214 658.979 286.1C660.959 287.986 663.694 288.928 667.183 288.928C670.718 288.928 673.453 287.986 675.386 286.1C677.366 284.214 678.356 281.409 678.356 277.684V246.356H686.418V277.543C686.418 281.55 685.546 284.945 683.801 287.726C682.057 290.508 679.723 292.582 676.8 293.949C673.877 295.317 670.648 296 667.112 296C663.576 296 660.347 295.317 657.424 293.949C654.548 292.582 652.261 290.508 650.564 287.726C648.867 284.945 648.018 281.55 648.018 277.543V246.356H656.08Z" fill="#25282A"/>
            <path d="M623.314 288.999H639.933V295.505H615.252V246.356H623.314V288.999Z" fill="#25282A"/>
            <path d="M580.8 296C576.227 296 572.007 294.939 568.141 292.818C564.323 290.649 561.282 287.655 559.019 283.837C556.803 279.971 555.695 275.633 555.695 270.825C555.695 266.016 556.803 261.702 559.019 257.883C561.282 254.064 564.323 251.094 568.141 248.973C572.007 246.804 576.227 245.72 580.8 245.72C585.42 245.72 589.64 246.804 593.458 248.973C597.324 251.094 600.365 254.064 602.581 257.883C604.797 261.702 605.905 266.016 605.905 270.825C605.905 275.633 604.797 279.971 602.581 283.837C600.365 287.655 597.324 290.649 593.458 292.818C589.64 294.939 585.42 296 580.8 296ZM580.8 288.999C584.053 288.999 586.952 288.268 589.498 286.807C592.044 285.298 594.024 283.177 595.438 280.442C596.9 277.661 597.631 274.455 597.631 270.825C597.631 267.194 596.9 264.012 595.438 261.278C594.024 258.543 592.044 256.445 589.498 254.984C586.952 253.522 584.053 252.791 580.8 252.791C577.547 252.791 574.647 253.522 572.102 254.984C569.556 256.445 567.552 258.543 566.091 261.278C564.676 264.012 563.969 267.194 563.969 270.825C563.969 274.455 564.676 277.661 566.091 280.442C567.552 283.177 569.556 285.298 572.102 286.807C574.647 288.268 577.547 288.999 580.8 288.999Z" fill="#25282A"/>
            <path d="M530.483 296C527.183 296 524.213 295.434 521.573 294.303C518.932 293.124 516.858 291.474 515.349 289.353C513.841 287.231 513.086 284.756 513.086 281.927H521.714C521.903 284.049 522.728 285.793 524.189 287.16C525.698 288.528 527.796 289.211 530.483 289.211C533.265 289.211 535.433 288.551 536.989 287.231C538.545 285.864 539.323 284.119 539.323 281.998C539.323 280.348 538.828 279.004 537.838 277.967C536.895 276.93 535.693 276.128 534.231 275.563C532.817 274.997 530.837 274.384 528.291 273.724C525.085 272.875 522.468 272.027 520.441 271.178C518.461 270.282 516.764 268.915 515.349 267.076C513.935 265.238 513.228 262.786 513.228 259.722C513.228 256.893 513.935 254.418 515.349 252.296C516.764 250.175 518.744 248.548 521.29 247.417C523.836 246.285 526.782 245.72 530.129 245.72C534.891 245.72 538.78 246.922 541.798 249.326C544.862 251.684 546.559 254.937 546.889 259.085H537.979C537.838 257.294 536.989 255.762 535.433 254.489C533.877 253.216 531.827 252.579 529.281 252.579C526.971 252.579 525.085 253.169 523.623 254.347C522.162 255.526 521.431 257.223 521.431 259.439C521.431 260.948 521.879 262.197 522.775 263.187C523.718 264.13 524.896 264.884 526.311 265.45C527.725 266.016 529.658 266.629 532.109 267.289C535.363 268.184 538.003 269.08 540.03 269.976C542.104 270.872 543.849 272.262 545.263 274.148C546.724 275.987 547.455 278.462 547.455 281.574C547.455 284.072 546.772 286.43 545.404 288.645C544.084 290.861 542.128 292.653 539.535 294.02C536.989 295.34 533.972 296 530.483 296Z" fill="#25282A"/>
            <path d="M464.066 252.863V267.289H481.039V273.866H464.066V288.929H483.16V295.506H456.005V246.286H483.16V252.863H464.066Z" fill="#25282A"/>
            <path d="M435.438 295.505L424.123 275.846H417.97V295.505H409.908V246.356H426.881C430.652 246.356 433.835 247.016 436.428 248.337C439.068 249.657 441.024 251.425 442.297 253.64C443.617 255.856 444.277 258.331 444.277 261.066C444.277 264.272 443.334 267.195 441.449 269.835C439.61 272.428 436.758 274.196 432.892 275.139L445.055 295.505H435.438ZM417.97 269.41H426.881C429.898 269.41 432.161 268.656 433.67 267.147C435.225 265.639 436.003 263.612 436.003 261.066C436.003 258.52 435.249 256.54 433.74 255.125C432.232 253.664 429.945 252.933 426.881 252.933H417.97V269.41Z" fill="#25282A"/>
            <path d="M375.456 296C370.883 296 366.664 294.939 362.798 292.818C358.979 290.649 355.938 287.655 353.675 283.837C351.459 279.971 350.351 275.633 350.351 270.825C350.351 266.016 351.459 261.702 353.675 257.883C355.938 254.064 358.979 251.094 362.798 248.973C366.664 246.804 370.883 245.72 375.456 245.72C380.076 245.72 384.296 246.804 388.115 248.973C391.98 251.094 395.021 254.064 397.237 257.883C399.453 261.702 400.561 266.016 400.561 270.825C400.561 275.633 399.453 279.971 397.237 283.837C395.021 287.655 391.98 290.649 388.115 292.818C384.296 294.939 380.076 296 375.456 296ZM375.456 288.999C378.709 288.999 381.609 288.268 384.154 286.807C386.7 285.298 388.68 283.177 390.095 280.442C391.556 277.661 392.287 274.455 392.287 270.825C392.287 267.194 391.556 264.012 390.095 261.278C388.68 258.543 386.7 256.445 384.154 254.984C381.609 253.522 378.709 252.791 375.456 252.791C372.203 252.791 369.304 253.522 366.758 254.984C364.212 256.445 362.208 258.543 360.747 261.278C359.332 264.012 358.625 267.194 358.625 270.825C358.625 274.455 359.332 277.661 360.747 280.442C362.208 283.177 364.212 285.298 366.758 286.807C369.304 288.268 372.203 288.999 375.456 288.999Z" fill="#25282A"/>
            <path d="M343.46 246.356V252.933H330.377V295.505H322.315V252.933H309.162V246.356H343.46Z" fill="#25282A"/>
            <path d="M283.808 296C280.508 296 277.538 295.434 274.898 294.303C272.258 293.124 270.183 291.474 268.675 289.353C267.166 287.231 266.412 284.756 266.412 281.927H275.039C275.228 284.049 276.053 285.793 277.514 287.16C279.023 288.528 281.121 289.211 283.808 289.211C286.59 289.211 288.758 288.551 290.314 287.231C291.87 285.864 292.648 284.119 292.648 281.998C292.648 280.348 292.153 279.004 291.163 277.967C290.22 276.93 289.018 276.128 287.556 275.563C286.142 274.997 284.162 274.384 281.616 273.724C278.41 272.875 275.793 272.027 273.766 271.178C271.786 270.282 270.089 268.915 268.675 267.076C267.26 265.238 266.553 262.786 266.553 259.722C266.553 256.893 267.26 254.418 268.675 252.296C270.089 250.175 272.069 248.548 274.615 247.417C277.161 246.285 280.107 245.72 283.455 245.72C288.216 245.72 292.106 246.922 295.123 249.326C298.187 251.684 299.885 254.937 300.215 259.085H291.304C291.163 257.294 290.314 255.762 288.758 254.489C287.203 253.216 285.152 252.579 282.606 252.579C280.296 252.579 278.41 253.169 276.949 254.347C275.487 255.526 274.756 257.223 274.756 259.439C274.756 260.948 275.204 262.197 276.1 263.187C277.043 264.13 278.221 264.884 279.636 265.45C281.05 266.016 282.983 266.629 285.435 267.289C288.688 268.184 291.328 269.08 293.355 269.976C295.429 270.872 297.174 272.262 298.588 274.148C300.05 275.987 300.78 278.462 300.78 281.574C300.78 284.072 300.097 286.43 298.73 288.645C297.41 290.861 295.453 292.653 292.86 294.02C290.314 295.34 287.297 296 283.808 296Z" fill="#25282A"/>
            <path d="M238.251 246.356V252.933H225.168V295.505H217.106V252.933H203.953V246.356H238.251Z" fill="#25282A"/>
            <path d="M185.931 295.505L174.616 275.846H168.464V295.505H160.402V246.356H177.374C181.146 246.356 184.328 247.016 186.921 248.337C189.561 249.657 191.518 251.425 192.791 253.64C194.111 255.856 194.771 258.331 194.771 261.066C194.771 264.272 193.828 267.195 191.942 269.835C190.103 272.428 187.251 274.196 183.385 275.139L195.549 295.505H185.931ZM168.464 269.41H177.374C180.391 269.41 182.654 268.656 184.163 267.147C185.719 265.639 186.497 263.612 186.497 261.066C186.497 258.52 185.742 256.54 184.234 255.125C182.725 253.664 180.439 252.933 177.374 252.933H168.464V269.41Z" fill="#25282A"/>
            <path d="M139.309 285.464H118.73L115.194 295.506H106.779L124.388 246.286H133.722L151.331 295.506H142.845L139.309 285.464ZM137.046 278.887L129.055 256.045L120.993 278.887H137.046Z" fill="#25282A"/>
            <path d="M97.6682 246.356V295.505H89.6064V261.844L74.6142 295.505H69.0275L53.9646 261.844V295.505H45.9028V246.356H54.6011L71.8562 284.898L89.0406 246.356H97.6682Z" fill="#25282A"/>
            <path d="M18.0037 296C14.7036 296 11.7334 295.434 9.0933 294.303C6.45317 293.124 4.37879 291.474 2.87015 289.353C1.36151 287.231 0.607178 284.756 0.607178 281.927H9.23474C9.42332 284.049 10.2484 285.793 11.7099 287.16C13.2185 288.528 15.3165 289.211 18.0037 289.211C20.7853 289.211 22.954 288.551 24.5098 287.231C26.0655 285.864 26.8434 284.119 26.8434 281.998C26.8434 280.348 26.3484 279.004 25.3584 277.967C24.4155 276.93 23.2133 276.128 21.7518 275.563C20.3374 274.997 18.3573 274.384 15.8115 273.724C12.6056 272.875 9.98906 272.027 7.96182 271.178C5.98172 270.282 4.2845 268.915 2.87015 267.076C1.4558 265.238 0.748612 262.786 0.748612 259.722C0.748612 256.893 1.4558 254.418 2.87015 252.296C4.2845 250.175 6.2646 248.548 8.81043 247.417C11.3563 246.285 14.3028 245.72 17.6501 245.72C22.4118 245.72 26.3013 246.922 29.3186 249.326C32.383 251.684 34.0802 254.937 34.4102 259.085H25.4998C25.3584 257.294 24.5098 255.762 22.954 254.489C21.3982 253.216 19.3474 252.579 16.8015 252.579C14.4914 252.579 12.6056 253.169 11.1441 254.347C9.68261 255.526 8.95187 257.223 8.95187 259.439C8.95187 260.948 9.39975 262.197 10.2955 263.187C11.2384 264.13 12.417 264.884 13.8314 265.45C15.2457 266.016 17.1787 266.629 19.6302 267.289C22.8832 268.184 25.5234 269.08 27.5506 269.976C29.625 270.872 31.3694 272.262 32.7837 274.148C34.2452 275.987 34.976 278.462 34.976 281.574C34.976 284.072 34.2924 286.43 32.9252 288.645C31.6051 290.861 29.6486 292.653 27.0556 294.02C24.5098 295.34 21.4925 296 18.0037 296Z" fill="#25282A"/>
          </svg>
          <!-- eslint-enable max-len -->
        </span>
      </div>
      <!-- index:made-by END -->

    </div>

    <!-- index:box:error:border START
    <div
      :class="[
        'error-border error-border-top',
        { 'show' : !boxOpened.isOpen || boxOpenedCatchError.isError }
      ]"
    ></div>
    <div
      :class="[
        'error-border error-border-right',
        { 'show' : !boxOpened.isOpen || boxOpenedCatchError.isError }
      ]"
    ></div>
    <div
      :class="[
        'error-border error-border-bottom',
        { 'show' : !boxOpened.isOpen || boxOpenedCatchError.isError }
      ]"
    ></div>
    <div
      :class="[
        'error-border error-border-left',
        { 'show' : !boxOpened.isOpen || boxOpenedCatchError.isError }
      ]"
    ></div>
    index:box:error:border END -->
  </div>
  <!-- index END -->
</template>

<script>
export default {
  name: 'index',
  components: {
    BoxStatus: () => import('../components/index/box/Status.vue'),
    BoxError: () => import('../components/index/box/Error.vue'),
    BoxBottomInfo: () => import('../components/index/box/Bottom.vue'),
  },
  data() {
    return {
      fakeHours: null,
      fakeHoursManned: null,

      /**
       * Settings
       */
      settings: null,
      intervalValue: 10000,

      /**
       * box (info)
       */
      boxOpened: {
        isOpen: true,
        status: '',
      },
      boxOpenedCatchError: {
        isError: false,
        status: '',
      },
      loginError: false,
      loginSuccess: false,

      /**
       * box (token)
       */
      user: null,
      box: null,
      boxToken: localStorage.getItem('boxToken') ? localStorage.getItem('boxToken') : 'undefined',
      boxLang: localStorage.getItem('boxLang') ? localStorage.getItem('boxLang') : 'undefined',
      /**
       * QR Code
       */
      qrcode: '',
      qrcodeTest: '',
      showQrcodeTest: false,
      /**
       * Root URL
       */
      rootUrl: localStorage.getItem('anybox_outdoor_api_url'),
    };
  },
  async created() {
    // get & set settings
    this.settings = await this.FETCH_SETTINGS();
    if (this.settings) {
      this.intervalValue = this.settings.app.timeouts.boxIsOpen;
    }

    // set token
    if (this.$route.query.token && this.$route.query.locale) {
      localStorage.setItem('boxToken', this.$route.query.token);
      localStorage.setItem('boxLang', this.$route.query.locale);
      this.boxToken = localStorage.getItem('boxToken');
      this.boxLang = localStorage.getItem('boxLang');
    }

    // if boxToxen then fetch box
    if (this.boxToken !== 'undefined' && this.boxLang !== 'undefined') {
      this.box = await this.FETCH_BOX();

      // check status of box
      if (this.box) {
        this.isBoxOpen();

        // GET FAKE HOURS
        this.fakeHours = await this.FETCH_FAKE_HOURS();

        // if (this.fakeHours) {
        //   this.fakeHours = this.fakeHours.sort(this.compare);
        // }

        if (this.fakeHours) {
          // Sort first
          this.fakeHours = this.fakeHours.sort(this.compare);
          // Then group
          this.fakeHours = this.groupHoursByWeekday(this.fakeHours);
        }

        console.log(this.fakeHours);

        // GET FAKE HOURS (MANNED)
        this.fakeHoursManned = await this.FETCH_FAKE_HOURS_MANNED();

        // if (this.fakeHoursManned) {
        //   this.fakeHoursManned = this.fakeHoursManned.sort(this.compare);
        // }

        if (this.fakeHoursManned) {
          // Sort first
          this.fakeHoursManned = this.fakeHoursManned.sort(this.compare);
          // Then group
          this.fakeHoursManned = this.groupHoursByWeekday(this.fakeHoursManned);
        }
      }
    }

    window.addEventListener('keydown', this.keyDownHandler);
  },
  methods: {
    groupHoursByWeekday(hours) {
      return hours.reduce((acc, day) => {
        const dayKey = day.weekday;
        if (!acc[dayKey]) {
          acc[dayKey] = [];
        }
        acc[dayKey].push(day);
        // Optional: Sort times for better display order
        acc[dayKey].sort((a, b) => this.$date(a.timeFrom, 'HH:mm:ss').valueOf() - this.$date(b.timeFrom, 'HH:mm:ss').valueOf());
        return acc;
      }, {});
    },

    async FETCH_SETTINGS() {
      await this.$store.dispatch('setSettings');
      return this.$store.getters.settings;
    },

    async FETCH_BOX() {
      await this.$store.dispatch('setBoxOutdoor', {
        payload: {
          token: this.boxToken,
        },
      });
      return this.$store.getters.boxOutdoor;
    },

    async FETCH_FAKE_HOURS() {
      await this.$store.dispatch('setFakeHours', {
        payload: {
          boxID: this.box.id,
        },
      });
      return this.$store.getters.fakeHours;
    },

    async FETCH_FAKE_HOURS_MANNED() {
      await this.$store.dispatch('setFakeHoursManned', {
        payload: {
          boxID: this.box.id,
        },
      });
      return this.$store.getters.fakeHoursManned;
    },

    onEnter() {
      // 6F0COFHJTMQG2DO4LXNUGAAFM6MI0SEEJ8FQMBBE5L3G6CT0GKBDHSTJF0FF2JHKEXO4
      // P43LCL56XS32DJBRU4F2P35C769QCJRSBLMXPID3XIBVVCEIIBPPLJ1H8EU7
      if (this.box && this.qrcodeTest && this.boxLang) {
        // login throught terminal
        this.loginDoor(this.box.id, this.qrcodeTest, this.boxLang);
      }
    },

    keyDownHandler(e) {
      const keyCode = e.keyCode || e.which;
      const code = e.code || '';
      let char = e.key;

      if (keyCode === 89 && code === 'KeyY' && char === 'Y') char = 'Z';
      else if (keyCode === 89 && code === 'KeyY' && char === 'y') char = 'z';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'Z') char = 'Y';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'z') char = 'y';

      if (char.length > 1) char = '';

      if (this.qrcodeTest === '') {
        this.qrcode += char;

        // login throught terminal
        if (e.keyCode === 13 && this.box && this.qrcode && this.boxLang) {
          this.loginDoor(this.box.id, this.qrcode, this.boxLang);
        }
      }
    },

    loginDoor(boxID, qrCode, boxLang) {
      const formData = new FormData();
      formData.append('boxID', boxID);
      formData.append('qrCode', qrCode);
      formData.append('locale', boxLang);
      this.$http.post(`${localStorage.getItem('anybox_outdoor_api_url')}/loginDoor`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('anybox_outdoor_api_key')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            this.loginSuccess = true;
            this.success(`${this.$t('outdoor.index.scan.success')}`);

            const { user } = response.data;
            const auth = response.data.token;

            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH_OUTDOOR', auth);
            localStorage.setItem('authOutdoor', auth);

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.user = user;
            this.$store.commit('SET_USER_OUTDOOR', user);
            localStorage.setItem('userOutdoor', JSON.stringify(user));

            // clear input
            setTimeout(() => {
              this.qrcode = '';
              this.qrcodeTest = '';
              this.logoutUser(boxLang);
            }, 150);

            // reset login success variable
            setTimeout(() => {
              this.loginSuccess = false;
            }, 6000);

            // delete created notification dom element
            setTimeout(() => {
              if (document.querySelector('.el-notification')) {
                const elem = document.querySelector('.el-notification');
                elem.remove();
              }
            }, 6500);
          }
        })
        .catch((error) => {
          this.loginError = true;
          this.errorStatus = error.response.data;
          this.serverErrorMessages = this.errorStatus.errors;

          this.qrcode = '';
          this.qrcodeTest = '';
          console.clear();

          this.serverErrorMessages.forEach((message) => {
            this.error(`${this.$t(`error.${message.code}`)}`);
          });

          // reset login error variable
          setTimeout(() => {
            this.loginError = false;
          }, 6000);

          // delete created notification dom element
          setTimeout(() => {
            if (document.querySelector('.el-notification')) {
              const elem = document.querySelector('.el-notification');
              elem.remove();
            }
          }, 6500);
        });
    },

    logoutUser(boxLang) {
      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', boxLang);
      // Logout data
      this.$http.post(`${localStorage.getItem('anybox_outdoor_api_url')}/logout`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('anybox_outdoor_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('authOutdoor')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH_OUTDOOR', null);
            localStorage.removeItem('authOutdoor');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER_OUTDOOR', null);
            localStorage.setItem('userOutdoor', null);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            this.errorStatus = error.response.data;
            console.log(this.errorStatus);
          }
        });
    },

    isBoxOpen() {
      this.isOpen();
      // check once in minute if opened or closed
      setInterval(() => {
        this.isOpen();
      }, this.intervalValue);
    },

    isOpen() {
      // check on init if opened or closed
      this.$http.get(`${localStorage.getItem('anybox_outdoor_api_url')}/box/isOpen`,
        {
          params: {
            boxID: this.box.id,
          },
          headers: {
            'X-Api-Key': `${localStorage.getItem('anybox_outdoor_api_key')}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const { isOpen, status } = response.data;

            // update notification
            this.boxOpened = {
              isOpen,
              status,
            };
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            // Close loading
            this.errorStatus = error.response.data;
            this.serverErrorMessages = this.errorStatus.errors;

            this.serverErrorMessages.forEach((message) => {
              this.boxOpenedCatchError = {
                isError: true,
                status: `${this.$t(`error.${message.code}`)}`,
              };
            });
          }
        });
    },

    success(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'success',
        position: 'bottom-right',
        showClose: false,
        duration: 6000,
      });
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
        showClose: false,
        duration: 6000,
      });
    },

    compare(a, b) {
      if (a.weekday < b.weekday) {
        return -1;
      }
      if (a.weekday > b.weekday) {
        return 1;
      }
      return 0;
    },
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
    clearInterval();
  },
  mounted() {
    if (Boolean(this.$route.query.showQrcodeTest) === true) {
      this.showQrcodeTest = true;
    } else {
      this.showQrcodeTest = false;
    }
  },
};
</script>
